import { QueryClient } from 'react-query';
import { queryBirkebeinerRouteByRouteId } from '../../data/birkebeiner/queries';
import { queryForecastByLocationId } from '../../data/forecast/queries';
import { queryWarningsByLocationId } from '../../data/warnings/queries';
import { TDataQuery } from '../../model/data';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';
import { DEFAULT_ROUTE_ID } from './BirkebeinerPage';

export class BirkebeinerPageHandler implements IPageHandler {
  getSettings() {
    return {
      isModal: false,
      showHeader: true,
      showFooter: true,
      expandedSearchInput: false
    };
  }

  getMetaData(_queryClient: QueryClient, _pageDetails: IPageDetails, translate: ITranslateFunction) {
    return {
      title: translate('meta/birkebeiner/title'),
      ogTitle: translate('meta/birkebeiner/ogTitle'),
      description: translate('meta/birkebeiner/description')
    };
  }

  async getDataQueries({ queryClient, pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode, routeId } = pageDetails.params;

    const queries: TDataQuery[] = [];

    const birkebeinerRouteQuery = queryBirkebeinerRouteByRouteId({ localeCode, routeId: routeId ?? DEFAULT_ROUTE_ID });

    // TODO(scb): Test what happens if this query fails. Is an error thrown or caught?
    const birkebeinerRoute = await queryClient.fetchQuery(birkebeinerRouteQuery);

    if (birkebeinerRoute != null) {
      // Prefetch forecast and warnings for all locations in this mountain pass
      if (birkebeinerRoute.locations != null) {
        for (const location of birkebeinerRoute.locations) {
          queries.push(queryForecastByLocationId({ locationId: location.id }));
          queries.push(queryWarningsByLocationId({ localeCode, locationId: location.id }));
        }
      }
    }

    return queries;
  }
}
