import { QueryClient } from 'react-query';
import { PageNotFoundError } from '../../app/errors';
import { getRegionByRegionId } from '../../data/regions/helpers';
import { getRegionFromQueryCache, queryRegions } from '../../data/regions/queries';
import { querySnowDepthsByRegionId } from '../../data/snowDepths/queries';
import { redirectToExpectedUrlPath } from '../../lib/helpers/url';
import { TDataQuery } from '../../model/data';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class SnowDepthsPageHandler implements IPageHandler {
  getSettings() {
    return {
      isModal: false,
      showHeader: true,
      showFooter: true,
      expandedSearchInput: false
    };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, subpageId: regionId } = pageDetails.params;

    if (regionId == null) {
      return {
        title: translate('meta/snowDepths/main/title'),
        ogTitle: translate('meta/snowDepths/main/ogTitle'),
        description: translate('meta/snowDepths/main/description')
      };
    }

    const region = getRegionFromQueryCache({ localeCode, regionId, queryClient });
    if (region == null) {
      return undefined;
    }

    const regionName = region.name;

    return {
      title: translate('meta/snowDepths/region/title', { regionName }),
      ogTitle: translate('meta/snowDepths/region/ogTitle', { regionName }),
      description: translate('meta/snowDepths/region/description', { regionName })
    };
  }

  async getDataQueries({ queryClient, pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode, subpageId: regionId } = pageDetails.params;

    const regionsQuery = queryRegions({ localeCode });
    const regions = await queryClient.fetchQuery(regionsQuery);

    // Check if the URL path is valid if we're on a region page
    if (regionId != null) {
      const region = getRegionByRegionId(regions, regionId);
      if (region == null) {
        throw new PageNotFoundError();
      }

      redirectToExpectedUrlPath({
        pageDetails,
        expectedUrlPath: region.urlPath
      });
    }

    const queries: TDataQuery[] = [];

    if (regionId != null) {
      queries.push(querySnowDepthsByRegionId({ localeCode, regionId: regionId }));
    }

    return queries;
  }
}
