import { QueryClient } from 'react-query';
import { PageRedirectError } from '../../app/errors';
import { createPageUrl } from '../../app/redirects';
import { queryForecastByLocationId } from '../../data/forecast/queries';
import { queryAllMountainPasses, queryMountainPassByRouteId } from '../../data/mountainPass/queries';
import { queryWarningsByLocationId } from '../../data/warnings/queries';
import { redirectToExpectedUrlPath } from '../../lib/helpers/url';
import { TDataQuery } from '../../model/data';
import { IMountainPass } from '../../model/mountainpass';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

type TSubpageId = 'south' | 'north';

const SUBPAGE_KEYS: { [key in TSubpageId]: string } = {
  south: 'south',
  north: 'north'
};

export class MountainPassPageHandler implements IPageHandler {
  getSettings() {
    return {
      isModal: false,
      showHeader: true,
      showFooter: true,
      expandedSearchInput: false
    };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, subpageId, routeId } = pageDetails.params;
    if (!isValidMountainPassSubpageId(subpageId)) {
      return undefined;
    }

    if (routeId == null) {
      const subpageKey = SUBPAGE_KEYS[subpageId];

      return {
        title: translate(`meta/mountainPass/${subpageKey}/title`),
        ogTitle: translate(`meta/mountainPass/${subpageKey}/ogTitle`),
        description: translate(`meta/mountainPass/${subpageKey}/description`)
      };
    }

    // TODO(scb): getMeta can be called before the mountain pass has finished fetching
    // when a user navigates to a new route using the dropdown.
    // If we were to use the `getMountainPassByRouteIdFromQueryCache()` helper this would trigger an error.
    // See https://nrknyemedier.atlassian.net/browse/YR-4774
    // const mountainPass = getMountainPassByRouteIdFromQueryCache({ localeCode, routeId, queryClient });
    const mountainPassQuery = queryMountainPassByRouteId({ localeCode, routeId });
    const mountainPass = queryClient.getQueryCache().find<IMountainPass>(mountainPassQuery.queryKey)?.state.data;
    if (mountainPass == null) {
      return undefined;
    }

    const routeName = mountainPass.route.name;

    return {
      title: translate('meta/mountainPass/route/title', { routeName }),
      ogTitle: translate('meta/mountainPass/route/ogTitle', { routeName }),
      description: translate('meta/mountainPass/route/description', { routeName })
    };
  }

  async getDataQueries({ queryClient, pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode, subpageId, pageId, routeId, urlPath } = pageDetails.params;

    const queries: TDataQuery[] = [];

    if (routeId == null) {
      queries.push(queryAllMountainPasses({ localeCode }));
    }

    if (routeId != null) {
      const mountainPassQuery = queryMountainPassByRouteId({ localeCode, routeId });

      // TODO(scb): Test what happens if this query fails. Is an error thrown or caught?
      const mountainPass = await queryClient.fetchQuery(mountainPassQuery);

      if (mountainPass != null) {
        // Redrirect to correct subpageId based on current mountain-pass-route
        const mountainPassCollection = mountainPass.route.collection === 'fjell-sor' ? 'south' : 'north';
        if (subpageId !== mountainPassCollection) {
          const href = createPageUrl({ localeCode, pageId, subpageId: mountainPassCollection, routeId, urlPath });
          throw new PageRedirectError(href);
        }
        redirectToExpectedUrlPath({ pageDetails, expectedUrlPath: mountainPass.route.urlPath });

        // Prefetch forecast and warnings for all locations in this mountain pass
        if (mountainPass.locations != null) {
          for (const location of mountainPass.locations) {
            queries.push(queryForecastByLocationId({ locationId: location.id }));
            queries.push(queryWarningsByLocationId({ localeCode, locationId: location.id }));
          }
        }
      }
    }

    return queries;
  }
}

function isValidMountainPassSubpageId(subpageId?: string): subpageId is TSubpageId {
  if (subpageId == null) {
    return false;
  }

  return SUBPAGE_KEYS.hasOwnProperty(subpageId);
}
