import queryString from 'query-string';
import { QueryClient } from 'react-query';
import { matchPath } from 'react-router';
import { queryExtremeEventList } from '../data/extreme/queries';
import { getLocationFromQueryCache, queryLocation } from '../data/locations/queries';
import { queryServiceAnnouncement } from '../data/serviceAnnouncement/queries';
import { captureMessage } from '../lib/errorReporter';
import { encode, redirectToExpectedUrlPath } from '../lib/helpers/url';
import { isLocaleCode, LocaleCode } from '../model/locale';
import { IPageDetails, IPageParams, IPageQuery, IUrlParams } from '../model/page';
import { IRoute } from '../model/route';
import { getRoutes } from './pages';

const routes = getRoutes();

export function getPageDetails(decodedPathname: string, search: string): IPageDetails | undefined {
  try {
    for (const route of routes) {
      const match = matchPath<IUrlParams>(decodedPathname, {
        path: route.pathTemplate,
        exact: true,
        strict: false
      });

      if (match == null) {
        continue;
      }

      const query = parseSearchString({ search, route });
      const params = getNormalizedPageRouteParameters({
        decodedPathname,
        search,
        route,
        params: match.params
      });

      // Create a key for this route based on page id and, if we have one, the
      // location id. This gives all subpages of a page the same key so that
      // React will reuse the page component when navigating from "foo/a" to
      // "foo/b", but navigating from "foo/a/1-72837/Norge/Oslo/Oslo/Oslo"
      // to "foo/b/1-72837/Norge/Oslo/Oslo/Oslo" will.
      const key = params.locationId != null ? `${params.pageId}/${params.locationId}` : params.pageId;

      return {
        key,
        name: route.name,
        component: route.component,
        pathTemplate: route.pathTemplate,
        pageId: route.pageId,
        subpageId: route.subpageId,
        pagePath: route.pagePath,
        subpagePath: route.subpagePath,
        handler: route.handler,
        params,
        query,
        search,
        hasLocaleCode: route.hasLocaleCode
      };
    }

    return undefined;
  } catch (error) {
    captureMessage('Error getting page details in getPageDetails()', {
      extra: {
        pathname: decodedPathname,
        error
      }
    });

    return undefined;
  }
}

// Each page can be accessed using multiple localized URLs.
// We don't want to have to deal with localized `page` and `subpage` strings so
// this helper returns normalized parameters where "normalized" means we prefer
// the english parameter name. This means for example that the `page` parameter
// on the forecast page is `forecast` even when accessing the `nb` URL where
// the `page` parameter really is `værvarsel`.
function getNormalizedPageRouteParameters({
  decodedPathname,
  search,
  route,
  params
}: {
  decodedPathname: string;
  search: string;
  route: IRoute;
  params: IUrlParams;
}): IPageParams {
  const url = decodedPathname + search;
  const { pageId, subpageId, subpagePath } = route;

  const localeCode = params.localeCode.toLowerCase() as LocaleCode;

  if (isLocaleCode(localeCode) === false) {
    throw Error('Invalid localeCode');
  }

  // Merging using the english page and subpage strings last
  // will overwrite the localized parameters.
  return {
    ...params,
    localeCode,
    url,
    pageId,

    // If the subpage path is a wildcard we want to use the subpage id
    // parameter as the subpage id. Doing this means a URL like
    // /en/extreme/
    subpageId: subpagePath === '*' ? params.subpageId : subpageId
  };
}

function parseSearchString({ search, route }: { search: string; route: IRoute }): IPageQuery {
  const parsedQuery = queryString.parse(search);
  const { i, q, lat, lon, lang, zoom } = parsedQuery;

  let encodedLang = typeof lang === 'string' ? encode(lang).toLowerCase() : 'nb';
  if (isLocaleCode(encodedLang) === false) {
    encodedLang = 'nb';
  }

  const debug = 'debug' in parsedQuery;

  const embedded = 'embedded' in parsedQuery;

  // The `i` parameter is used on pages that show data for a specific day.
  // We default to `0` if the parameter is missing.
  const parsedI = typeof i === 'string' ? parseInt(i, 10) : 0;

  // The `q` parameter is used by the search page and the statistics page
  // We encode the parameter to prevent HTML injection.
  let encodedQ = typeof q === 'string' ? encode(q) : undefined;

  // Transform the query parameter string if the route has translated query parameters
  if (route.query != null && encodedQ != null && route.query.q[encodedQ] != null) {
    encodedQ = route.query.q[encodedQ];
  }

  // The `lat`, and `lon` parameters are used by the search page.
  // We encode the parameters to prevent HTML injection.
  const encodedLat = typeof lat === 'string' ? parseFloat(encode(lat)) : undefined;
  const encodedLon = typeof lon === 'string' ? parseFloat(encode(lon)) : undefined;

  // We are using zoom levels 1 - 12 in our maps so we need to ensure we are in this range.
  const floatZoom = typeof zoom === 'string' ? parseFloat(zoom) : undefined;
  const parsedZoom = floatZoom && floatZoom >= 1 && floatZoom <= 12 ? floatZoom : undefined;

  return {
    lang: encodedLang as LocaleCode,
    embedded,
    debug,
    i: parsedI,
    q: encodedQ,
    lat: encodedLat,
    lon: encodedLon,
    zoom: parsedZoom
  };
}

interface IFetchPageDataOptions {
  pathname: string;
  search: string;
  queryClient: QueryClient;
  onFetchStart?: () => void;
  onFetchDone: () => void;
  onFetchError?: (error: Error) => void;
}

export function fetchPageData({
  pathname,
  search,
  queryClient,
  onFetchStart,
  onFetchDone,
  onFetchError
}: IFetchPageDataOptions) {
  const pageDetails = getPageDetails(pathname, search);

  if (pageDetails == null) {
    throw new Error(`fetchPageData() could not fetch data for missing page "${pathname}"`);
  }

  if (onFetchStart) {
    onFetchStart();
  }

  prefetchPageData({ queryClient, pageDetails })
    .then(
      () => {
        // Notify that the handlers have finished
        onFetchDone();
      },
      error => {
        if (onFetchError) {
          onFetchError(error);
        }
      }
    )
    .catch(error => {
      if (onFetchError) {
        onFetchError(error);
      }
    });
}

async function prefetchPageData({ queryClient, pageDetails }: { queryClient: QueryClient; pageDetails: IPageDetails }) {
  const { handler } = pageDetails;

  await prefetchSharedPageData(queryClient, pageDetails);

  if (handler.getDataQueries != null) {
    const queries = await handler.getDataQueries({ queryClient, pageDetails });
    const promises = queries.map(query => queryClient.prefetchQuery(query));

    await Promise.all(promises);
  }
}

async function prefetchSharedPageData(queryClient: QueryClient, pageDetails: IPageDetails): Promise<void> {
  const { params } = pageDetails;
  const { localeCode, locationId } = params;

  await Promise.all([
    queryClient.prefetchQuery(queryServiceAnnouncement({ localeCode })),
    // If the location api returns an error while fetching we should not suppress the error
    // so we use `fetchQuery` instead of `prefetchQuery`.
    // See https://nrknyemedier.atlassian.net/browse/YR-4771
    // TODO(scb): Do this for other fetches from handlers also, e.g. regions.
    // This can be maybe done by handlers returning an array of results from a new
    // `prefetch({ query: ..., critical: true})` wrapper. Possibly the `critical: true`
    // should only be active on the server? In the browser we can rely on component's
    // error handling I think.
    queryClient.fetchQuery(queryLocation({ localeCode, locationId }))
  ]);

  if (locationId != null) {
    const location = getLocationFromQueryCache({ queryClient, locationId, localeCode });

    if (location == null) {
      throw new Error(`Location not found while checking if the location path is valid for locationId "${locationId}"`);
    }

    if (location.hasExtremeEventList) {
      await queryClient.prefetchQuery(queryExtremeEventList({ localeCode, locationId }));
    }

    redirectToExpectedUrlPath({ pageDetails, expectedUrlPath: location.urlPath });
  }
}
