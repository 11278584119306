import { QueryClient } from 'react-query';
import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiRegions } from '../../model/api/region';
import { LocaleCode } from '../../model/locale';
import { IRegion } from '../../model/region';
import { parseRegions, parseRegionNames } from './parse';
import { api } from '../api';
import { getRegionByRegionId } from './helpers';

export function queryRegions({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['regions', localeCode];

  async function queryFn() {
    const response = await api<IApiRegions>({
      path: template(settings.api.paths.regions, { localeCode })
    });

    return parseRegions(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryRegionNames({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['region-names', localeCode];

  async function queryFn() {
    const response = await api<IApiRegions>({
      path: template(settings.api.paths.regions, { localeCode })
    });

    return parseRegionNames(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function getRegionFromQueryCache({
  localeCode,
  regionId,
  queryClient
}: {
  localeCode: LocaleCode;
  regionId: string;
  queryClient: QueryClient;
}) {
  const { queryKey: regionsQueryKey } = queryRegions({ localeCode });

  const regions = queryClient.getQueryCache().find<IRegion[]>(regionsQueryKey)?.state.data;
  const region = regions ? getRegionByRegionId(regions, regionId) : null;

  if (region == null) {
    throw new Error("Can't find region in QueryCache, make sure that it is prefetched.");
  }

  return region;
}
