import { useAppState } from '../../app/contexts/AppStateContext';
import { Heading } from '../../components/Heading/Heading';
import { Loader } from '../../components/Loader/Loader';
import { PageRoot } from '../../components/PageRoot/PageRoot';
import { RouteForecastList } from '../../components/RouteForecastList/RouteForecastList';
import { Text } from '../../components/Text/Text';
import { WebcamCardList } from '../../components/WebcamCardList/WebcamCardList';
import { useFetchBirkebeinerRouteByRouteId } from '../../data/birkebeiner/hooks';
import { useTranslate } from '../../lib/hooks/useTranslate';
import './BirkebeinerPage.scss';

export const DEFAULT_ROUTE_ID = '60';

export default function BirkebeinerPage() {
  const translate = useTranslate();
  const { currentPage } = useAppState();
  const { routeId } = currentPage.details.params;

  const { data: birkebeinerRoute, isLoading: routeIsLoading } = useFetchBirkebeinerRouteByRouteId({
    routeId: routeId ?? DEFAULT_ROUTE_ID
  });

  if (routeIsLoading) {
    return <Loader type="block" />;
  }

  if (birkebeinerRoute == null) {
    return null;
  }

  const { route, locations, roadCameras } = birkebeinerRoute;

  return (
    <PageRoot>
      <div className="birkebeiner-page">
        <div className="layout-container layout-container--with-padding">
          <Heading level="h2" size="1">
            {route.name}
          </Heading>
          <Text className="birkebeiner-page__description" size="4">
            {translate('birkebeinerPage/description')}
          </Text>
          <>
            <div className="birkebeiner-page__route-forecast">
              <Heading level="h2" size="2">
                <span aria-hidden>{translate('mountainPassPage/routeForecastHeading/displayValue')}</span>
                <span className="nrk-sr">{translate('mountainPassPage/routeForecastHeading/screenReader')}</span>
              </Heading>
              {locations != null && locations.length > 0 && <RouteForecastList locations={locations} />}
            </div>

            {roadCameras != null && roadCameras.length > 0 && (
              <div className="birkebeiner-page__road-cams">
                <WebcamCardList cameras={roadCameras} />
              </div>
            )}
          </>
        </div>
      </div>
    </PageRoot>
  );
}
