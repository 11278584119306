import { QueryClient } from 'react-query';
import { PageNotFoundError } from '../../app/errors';
import { queryCurrentHourByLocationId } from '../../data/currentHour/queries';
import { queryForecastByLocationId } from '../../data/forecast/queries';
import { getLocationFromQueryCache } from '../../data/locations/queries';
import { queryNowcastByLocationId } from '../../data/nowcast/queries';
import { queryCelestialEventsMultipleDaysByLocationId } from '../../data/celestialEvents/queries';
import { queryWarningsByLocationId } from '../../data/warnings/queries';
import { TDataQuery } from '../../model/data';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

type TSubpageId = 'daily-table' | 'graph' | 'hourly-table';

const SUBPAGE_KEYS: { [key in TSubpageId]: string } = {
  'daily-table': 'dailyTable',
  graph: 'graph',
  'hourly-table': 'hourlyTable'
};

export class ForecastPageHandler implements IPageHandler {
  getSettings({ pageDetails }: { pageDetails: IPageDetails }) {
    const { subpageId } = pageDetails.params;
    const isHourly = subpageId === 'hourly-table';

    return { isModal: isHourly, showHeader: true, showFooter: true, expandedSearchInput: false };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, subpageId, locationId } = pageDetails.params;
    if (!isValidForecastSubpageId(subpageId) || locationId == null) {
      return undefined;
    }

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });
    if (location == null) {
      return undefined;
    }

    const locationName = location.name;
    const subpageKey = SUBPAGE_KEYS[subpageId];

    return {
      title: translate(`meta/forecast/${subpageKey}/title`, { locationName }),
      ogTitle: translate(`meta/forecast/${subpageKey}/ogTitle`, { locationName }),
      description: translate(`meta/forecast/${subpageKey}/description`, { locationName })
    };
  }

  async getDataQueries({ queryClient, pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { locationId, localeCode } = pageDetails.params;
    if (locationId == null) {
      throw new PageNotFoundError();
    }

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });

    const queries: TDataQuery[] = [
      queryForecastByLocationId({ locationId }),
      queryCurrentHourByLocationId({ locationId }),

      queryCelestialEventsMultipleDaysByLocationId({ locationId })
    ];

    if (location && location.hasNowcast) {
      queries.push(queryNowcastByLocationId({ locationId }));
    }

    if (location && location.hasWarnings) {
      queries.push(queryWarningsByLocationId({ localeCode, locationId }));
    }

    return queries;
  }
}

function isValidForecastSubpageId(subpageId?: string): subpageId is TSubpageId {
  if (subpageId == null) {
    return false;
  }

  return SUBPAGE_KEYS.hasOwnProperty(subpageId);
}
