import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { ITranslateFunction } from '../../model/translate';
import { useAriaLive } from '../AriaLiveContext/AriaLiveContext';
import { useToast } from '../ToastContext/ToastContext';
import { reducer } from './helpers/reducer';
import { getLocalStorageLocationIds, setLocalStorageLocationIds } from './helpers/storage';

interface IProps {
  initialTotalFavouritedLocations: number;
  initialTotalVisitedLocations: number;
  children: React.ReactNode;
}
interface IContext {
  initialTotalFavouritedLocations: number;
  initialTotalVisitedLocations: number;
  favouritedLocationIds: string[];
  visitedLocationIds: string[];
  prepopulatedLocationIds: string[];
  addFavouritedLocation: ({
    locationId,
    locationName,
    translate
  }: {
    locationId: string;
    locationName: string;
    translate: ITranslateFunction;
  }) => void;
  removeFavouritedLocation: ({
    locationId,
    locationName,
    translate
  }: {
    locationId: string;
    locationName: string;
    translate: ITranslateFunction;
  }) => void;
  addVisitedLocation: (locationId: string) => void;
  removeVisitedLocation: (locationId: string) => void;
  removePrepopulatedLocation: (locationId: string) => void;
}

const LocationListContext = createContext<IContext | undefined>(undefined);

export function LocationListProvider(props: IProps) {
  const { initialTotalFavouritedLocations, initialTotalVisitedLocations, children } = props;

  const { addToast } = useToast();
  const { addAriaLive } = useAriaLive();
  // We can't get isFirstRender from useAppState since this context is rendered first,
  // so we just recreate our own.
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [state, dispatch] = useReducer(reducer, getLocalStorageLocationIds());

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  // Store locations to local storage whenever the state changes
  useEffect(() => {
    const { favouritedLocationIds, visitedLocationIds, prepopulatedLocationIds } = state;

    setLocalStorageLocationIds({ favouritedLocationIds, visitedLocationIds, prepopulatedLocationIds });
  }, [state]);

  const addFavouritedLocation = useCallback(
    ({
      locationId,
      locationName,
      translate
    }: {
      locationId: string;
      locationName: string;
      translate: ITranslateFunction;
    }) => {
      const parsedLocationName = locationName.length > 30 ? `${locationName.slice(0, 30)}...` : locationName;
      const text = translate('notifications/locations/added', { locationName: `«${parsedLocationName}»` });

      addToast({
        type: 'NOTIFICATION',
        content: { iconId: 'icon-star-filled', text }
      });

      addAriaLive({ text });
      dispatch({ type: 'FAVOURITED_LOCATION_ADD', locationId });
    },
    [addToast, addAriaLive]
  );

  const removeFavouritedLocation = useCallback(
    ({
      locationId,
      locationName,
      translate
    }: {
      locationId: string;
      locationName: string;
      translate: ITranslateFunction;
    }) => {
      const parsedLocationName = locationName.length > 30 ? `${locationName.slice(0, 30)}...` : locationName;
      const text = translate('notifications/locations/removed', { locationName: `«${parsedLocationName}»` });

      addToast({
        type: 'NOTIFICATION',
        content: { iconId: 'icon-star', text }
      });

      addAriaLive({ text });
      dispatch({ type: 'FAVOURITED_LOCATION_REMOVE', locationId });
    },
    [addToast, addAriaLive]
  );

  const addVisitedLocation = useCallback((locationId: string) => {
    dispatch({ type: 'VISITED_LOCATION_ADD', locationId });
  }, []);

  const removeVisitedLocation = useCallback((locationId: string) => {
    dispatch({ type: 'VISITED_LOCATION_REMOVE', locationId });
  }, []);

  const removePrepopulatedLocation = useCallback((locationId: string) => {
    dispatch({ type: 'PREPOPULATED_LOCATION_REMOVE', locationId });
  }, []);

  const value = useMemo(() => {
    // When rendering on the server we don't know what the user's locations are,
    // so we return empty lists during the first render in the browser also.
    const favouritedLocationIds = isFirstRender ? [] : state.favouritedLocationIds;
    const visitedLocationIds = isFirstRender ? [] : state.visitedLocationIds;
    const prepopulatedLocationIds = isFirstRender ? [] : state.prepopulatedLocationIds;

    return {
      initialTotalFavouritedLocations,
      initialTotalVisitedLocations,
      favouritedLocationIds,
      visitedLocationIds,
      prepopulatedLocationIds,
      addFavouritedLocation,
      removeFavouritedLocation,
      addVisitedLocation,
      removeVisitedLocation,
      removePrepopulatedLocation
    };
  }, [
    isFirstRender,
    initialTotalFavouritedLocations,
    initialTotalVisitedLocations,
    state.favouritedLocationIds,
    state.visitedLocationIds,
    state.prepopulatedLocationIds,
    addFavouritedLocation,
    removeFavouritedLocation,
    addVisitedLocation,
    removeVisitedLocation,
    removePrepopulatedLocation
  ]);

  return <LocationListContext.Provider value={value}>{children}</LocationListContext.Provider>;
}

export function useLocationList() {
  const context = useContext(LocationListContext);

  if (context === undefined) {
    throw new Error('useLocationList must be used within a LocationListProvider');
  }

  return context;
}
