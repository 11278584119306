import { useMemo } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

// React Query has a "loading" status which is only active when initially
// loading the data. If the request is succcessful the status is set to "success"
// and remains as "success" even when the data is refetched later.
// Errors, however, do not behave the same way and the status "error"
// gets reset to "loading" when the data is refetched later.
// This is unlikely to change in React Query itself,
// see https://github.com/tannerlinsley/react-query/issues/2673,
// So we use this custom wrapper around `useQuery` where both the "success" and
// "error" status are preserved when data is refethed.
// This simplifies loading/success/error handling in our components a lot,
// and means we won't accidentally toggle between an error message to a loading
// message whenever a refetch is triggered.
// The types from this wrapper are copied over from the React Query source.
export function useQueryWrapper<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  const result = useQuery(options);

  return useMemo(() => {
    let status = result.status;
    if (status === 'loading' && result.errorUpdatedAt > 0) {
      status = 'error';
    }

    return {
      ...result,
      status,
      isError: status === 'error',
      isLoading: status === 'loading'
    };
  }, [result]);
}
