import { IAPILocation } from '../../model/api/location';
import settings from '../../app/settings';
import { template } from '../../lib/string';
import { LocaleCode } from '../../model/locale';
import { parseLocation } from './parse';
import { api } from '../api';
import { QueryClient } from 'react-query';
import { ILocation } from '../../model/location';

export function queryLocation({ localeCode, locationId }: { localeCode: LocaleCode; locationId?: string }) {
  const queryKey = ['location', localeCode, locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IAPILocation>({
      path: template(settings.api.paths.location, { localeCode, locationId })
    });

    return parseLocation(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function getLocationFromQueryCache({
  localeCode,
  locationId,
  queryClient
}: {
  localeCode: LocaleCode;
  locationId?: string;
  queryClient: QueryClient;
}) {
  const { queryKey: locationQueryKey } = queryLocation({ localeCode, locationId });

  const location = queryClient.getQueryCache().find<ILocation>(locationQueryKey)?.state.data;
  if (location == null) {
    throw new Error("Can't find location in QueryCache, make sure that it is prefetched.");
  }

  return location;
}
