import CoreDialog from '@nrk/core-dialog/jsx';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createPageUrl } from '../../app/redirects';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { CloseButton } from '../CloseButton/CloseButton';
import { ClickableLink } from '../Clickable/Clickable';
import { ServedBy } from '../ServedBy/ServedBy';
import './Menu.scss';
import { Menu__LanguageSelector } from './Menu__LanguageSelector';
import noScroll from 'no-scroll';
import { useMapSubpageId } from '../../lib/hooks/useMapSubpageId';
import { Text } from '../Text/Text';

interface IProps {
  onToggle: () => void;
}

export const Menu = (props: IProps) => {
  const { onToggle } = props;
  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const mapSubpageId = useMapSubpageId();
  const menuDialogRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  // We want to toggle no scroll before the browser renders this component for the first time.
  useEffect(() => {
    noScroll.on();

    return () => {
      noScroll.off();
    };
  }, []);

  useEffect(() => {
    if (isVisible === false) {
      setIsVisible(true);
    }
  }, [isVisible]);

  const menuStrings = [
    {
      text: translate('nav/menu/map'),
      url: createPageUrl({
        localeCode,
        pageId: 'standalone-map',
        subpageId: mapSubpageId
      })
    },
    {
      text: translate('nav/menu/weatherWarnings'),
      url: createPageUrl({
        localeCode,
        pageId: 'weather-warnings'
      })
    },
    {
      text: translate('nav/menu/waterTemperatures'),
      url: createPageUrl({
        localeCode,
        pageId: 'water-temperatures-region-list'
      })
    },
    {
      text: translate('nav/menu/snowDepths'),
      url: createPageUrl({
        localeCode,
        pageId: 'snow-depth-region-list'
      })
    },
    {
      text: translate('nav/menu/mountainpasses'),
      url: createPageUrl({
        localeCode,
        pageId: 'mountain-passes',
        subpageId: 'south'
      })
    },
    // The link to BirkebeinerPage is only active a week before and during a Birkebeiner event
    {
      // We have one key for each Birkebeiner event in lokalise: `birkebeinerrittet`, `birkebeinerløpet` and `birkebeinerrennet`
      text: translate('birkebeinerrittet'),
      url: createPageUrl({
        localeCode,
        pageId: 'birkebeineren'
      })
    },
    { text: translate('link/help/text'), url: translate('link/help/url') },
    { text: translate('link/contact/text'), url: translate('link/contact/url') }
  ];

  const menuItems = (
    <ul>
      {menuStrings.map(item => {
        return (
          <li key={item.text} className="menu__list-item">
            <ClickableLink className="menu__link" href={item.url} onClick={onToggle}>
              <Text size="4" weight="bold">
                {item.text}
              </Text>
            </ClickableLink>
          </li>
        );
      })}
      <li className="menu__list-item">
        <Menu__LanguageSelector />
      </li>
    </ul>
  );

  const closeButton = <CloseButton as="button" buttonSize="small" className="menu__close-button" onClick={onToggle} />;

  const modalRoot = document.querySelector('#modalRoot');
  if (modalRoot == null) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="menu">
      <div
        className={classNames({ menu__dialog: true, 'menu__dialog--visible': isVisible })}
        ref={menuDialogRef}
        data-testid="modal"
      >
        <CoreDialog
          className="menu__core-dialog"
          aria-label={translate('header/menu')}
          onDialogToggle={() => {
            onToggle();
          }}
        >
          <div className="menu__dialog-container">
            {closeButton}
            <div className="menu__dialog-content">
              {menuItems}
              <div className="menu__served-by">
                <ServedBy type="logo" />
              </div>
            </div>
          </div>
        </CoreDialog>
      </div>
    </div>,
    modalRoot
  );
};
