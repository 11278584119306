import { QueryClient } from 'react-query';
import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiAllMountainPassesResponse, IApiMountainPassResponse } from '../../model/api/mountainpass';
import { LocaleCode } from '../../model/locale';
import { IMountainPass } from '../../model/mountainpass';
import { api } from '../api';
import { parseAllMountainPasses, parseMountainPass } from './parse';

export function queryAllMountainPasses({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['routeForecastAllMountainpasses', localeCode];

  async function queryFn() {
    const response = await api<IApiAllMountainPassesResponse>({
      path: template(settings.api.paths.routeForecastAllMountainpasses, { localeCode })
    });

    return parseAllMountainPasses(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryMountainPassByRouteId({ localeCode, routeId }: { localeCode: LocaleCode; routeId?: string }) {
  const queryKey = ['routeForecastMountainpass', routeId, localeCode];

  async function queryFn() {
    if (routeId == null) {
      return;
    }

    const response = await api<IApiMountainPassResponse>({
      path: template(settings.api.paths.routeForecastMountainpass, { localeCode, routeId })
    });

    return parseMountainPass(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function getMountainPassByRouteIdFromQueryCache({
  localeCode,
  routeId,
  queryClient
}: {
  localeCode: LocaleCode;
  routeId: string;
  queryClient: QueryClient;
}) {
  const { queryKey: mountainPassQueryKey } = queryMountainPassByRouteId({ localeCode, routeId });

  const mountainPass = queryClient.getQueryCache().find<IMountainPass>(mountainPassQueryKey)?.state.data;
  if (mountainPass == null) {
    throw new Error("Can't find mountainPass in QueryCache, make sure that it is prefetched.");
  }

  return mountainPass;
}
